import React from 'react'
import graphicDesign from "../assets/features/graphic_design.png"
import itConsultancy from "../assets/features/it_consultancy.png"
import cyberSecurity from "../assets/features/cyber_security.png"
import softwareEngineering from "../assets/features/software_engineering.png"
import FeatureItem from '../components/FeatureItem'

const Features = () => {
    const features = [
        {
            icon: graphicDesign,
            title: "Graphic Design",
            text:"Epahubb offers brand identity, print, digital, UI/UX, illustration, packaging, and motion graphics design services."
        },
        {
            icon: itConsultancy,
            title: "IT Consultancy",
            text:"Epahubb Consult provides technology strategy, system optimization and IT infrastructure advice"
        },
        {
            icon: cyberSecurity,
            title: "Cyber Security",
            text:"Protect your systems, networks, and data from digital attacks and unauthorized access."
        },
        {
            icon: softwareEngineering,
            title: "Software Engineering",
            text:"Epahubb Consult offers custom software development, integration, mobile apps, web applications, testing, and maintenance."
        }
    ]
  return (
    <div id='work'>
        <div className="container py-20 pb-32">
            <h2 className='text-center md:text-[70px] md:leading-[70px] text-[30px] font-[200] w-4/5 md:w-full mx-auto text-[#091542]' data-aos="fade-up" data-aos-duration="1000">
                Excellent tailored <span className='font-[700]'>features</span> just for you
            </h2>

            {/* FEATURES */}
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-20">
                {
                    features?.map((feature, index)=>(
                        <FeatureItem featureItem={feature} key={index} />
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default Features