import React from 'react'
import expertise from "../assets/skills/expertise.png"
import problemSolving from "../assets/skills/problem.png"
import communication from "../assets/skills/communication.png"
import adaptability from "../assets/skills/adaptability.png"
import SkillItem from '../components/SkillItem'

const Skills = () => {
    const skills = [
        {
            icon: expertise,
            title: "Expertise",
            text:"Epahubb Consult have a deep knowledge in relevant technologies and industry trends. We hit right in the bull’s eye."
        },
        {
            icon: problemSolving,
            title: "Problem Solving Skills",
            text:"We at Epahubb Consult have very skilled  experts that has the ability to diagnose and address complex technical issues."
        },
        {
            icon: communication,
            title: "Communication",
            text:"Our staffs have a very clear and effective communication with clients and team members; we that we are very committed to."
        },
        {
            icon: adaptability,
            title: "Adaptability",
            text:"At Epahubb Consult, we are very Flexibile to adjust solutions and strategies as technologies and client needs evolve."
        }
    ]
  return (
    <div className=''>
        <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="grid md:grid-cols-2 grid-cols-1 gap-10 bg-[#091542] lg:order-1 order-2 text-white md:px-[150px] lg:px-[50px] lg:py-[100px] md:py-[100px]">
                {
                    skills?.map((skill, index)=>(
                        <SkillItem skill={skill} key={index}/>
                    ))
                }
            </div>
            <div className='bg-skills-image p-10 text-center lg:order-2 order-1 text-white lg:h-auto h-[300px] bg-cover flex items-center justify-center'>
                <p className='text-[23px] font-[300] lg:hidden'>
                    WE EMPOWER BUSINESSES WITH <span className='font-[700]'>CUTTING-EDGE SOLUTIONS</span> FOR SUSTAINED GROWTH AND EFFICIENCY
                </p>
            </div>
        </div>
    </div>
  )
}

export default Skills