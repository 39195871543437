import React from "react";

const Contact = () => {
  return (
    <div id="contact" className="lg:min-h-screen py-40 mb-3 md:mb-0">
      <div className="container grid lg:grid-cols-3 lg:gap-10">
        <div>
          <h3 className="8xl:text-[90px] text-[60px] text-center text-[#091542] font-[200] md:leading-[80px] mb-20 md:mb-10">Let's <span className="font-[600]">contact</span> for better result</h3>
          <div className="text-center mt-20 md:mt-5 flex flex-col gap-3">
            <a href="" className="text-[#091542] font-[500] underline">info@epahubb.com</a>
            <p>+233 531 178 671</p>
            <p>AK-359-7809 Panpan Street. </p>
            <p>Agric Nsema, Kumasi Ghana</p>
          </div>
        </div>
        <form action="" className="col-span-2 mt-5 md:mt-0">
          <div className="flex flex-col">
            <label className="text-[#091542]" htmlFor="">Name</label>
            <input
              type="text"
              name=""
              id=""
              className="border border-[#8D8D8D] rounded px-4 py-3"
            />
          </div>
          <div className="grid md:grid-cols-2 mt-4 gap-5">
            <div className="flex flex-col">
              <label className="text-[#091542]" htmlFor="">Email</label>
              <input
                type="email"
                name=""
                id=""
                className="border border-[#8D8D8D] rounded px-4 py-3"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-[#091542]" htmlFor="">Phone</label>
              <input
                type="text"
                name=""
                id=""
                className="border border-[#8D8D8D] rounded px-4 py-3"
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 mt-4 gap-5">
            <div className="flex flex-col">
              <label className="text-[#091542]" htmlFor="">Company</label>
              <input
                type="text"
                name=""
                id=""
                className="border border-[#8D8D8D] rounded px-4 py-3"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-[#091542]" htmlFor="">Address</label>
              <input
                type="text"
                name=""
                id=""
                className="border border-[#8D8D8D] rounded px-4 py-3"
              />
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <label className="text-[#091542]" htmlFor="">Website</label>
            <input
              type="text"
              name=""
              id=""
              className="border border-[#8D8D8D] rounded px-4 py-3"
            />
          </div>
          <div className="flex flex-col mt-4">
            <label className="text-[#091542]" htmlFor="">Message</label>
            <textarea className="border border-[#8D8D8D] rounded px-4 py-3" name="" id="" cols="30" rows="10"></textarea>
            
          </div>
          <button className="bg-[#1C274E] text-white rounded-lg px-9 py-4 mt-4">
            Submit Now
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
