import React from 'react'
import gilatChemist from "../assets/brands/gilat_chemist.png"
import ernestChemist from "../assets/brands/ernest_chemist.png"
import stewardsPharmacy from "../assets/brands/stewards.png"
import unionPharmacy from "../assets/brands/union_pharmacy.png"

const Brands = () => {
  return (
    <div className='bg-[#FEF2ED] h-[300px] flex items-center justify-center md:mt-0 mt-[250px]'>
      <div className="container">
        <div className="grid lg:grid-cols-5 items-center gap-3">
          <div className='lg:border-l-2 lg:border-b-0 border-b-2 text-center border-[#1C274E]  text-[#1C274E] p-5'>
              Worked with world's famous brands and partners
          </div>
          <div className='lg:col-span-4 grid grid-cols-4 items-center justify-center'>
             <img src={stewardsPharmacy} alt="" data-aos="fade-up" data-aos-duration="1000"/>
             <img src={gilatChemist} alt="" data-aos="fade-down" data-aos-duration="1000" />
             <img src={ernestChemist} alt="" data-aos="fade-up" data-aos-duration="1000" />
             <img src={unionPharmacy} alt="" data-aos="fade-down" data-aos-duration="1000" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Brands