import React from 'react'

const About = () => {
  return (
    <div className='bg-[#FEF2ED]'>
        <div className="container md:py-32 py-10">
            <h2 className='text-center md:text-[60px] text-[25px] font-[200] text-[#091542] md:w-4/5 mx-auto md:leading-[70px]' data-aos="fade-up" data-aos-duration="1000">
                You <span className='font-[600]'>Propose</span>, we <span className='font-[600]'>develop</span>; Just as you want it. With Epahubb, its always a win-win
            </h2>
            <div className='grid lg:grid-cols-4 grid-cols-2 mt-20'>
                <div className="pt-14 md:border-t border-r border-gray-300">
                    <h3 className='text-[#091542] text-[120px] font-[500] leading-[90px]' data-aos="fade-right" data-aos-duration="1000">2+</h3>
                    <h4 className='text-[#091542] text-[20px] font-[400] '>Year Experience</h4>
                    <p className='text-[12px]'>
                        With 2 years of experience, we’ve earned an astounding reputation by giving our customers maximum satisfaction.  
                    </p>
                </div>
                <div className="pt-14  px-7 md:border-t md:border-r border-b md:border-b-0 border-gray-300">
                    <h3 className='text-[#091542] text-[120px] font-[500] leading-[90px]' data-aos="fade-down" data-aos-duration="1000">15</h3>
                    <h4 className='text-[#091542] text-[20px] font-[400] '>Companies</h4>
                    <p className='text-[12px]'>
                        Epahubb Consult has worked with a range of companies, community, firms and groups around the globe
                    </p>
                </div>
                <div className="pt-14  px-7 border-t border-r border-gray-300">
                    <h3 className='text-[#091542] text-[120px] font-[500] leading-[90px]' data-aos="fade-up" data-aos-duration="1000">1k</h3>
                    <h4 className='text-[#091542] text-[20px] font-[400] '>Customers</h4>
                    <p className='text-[12px]'>
                        We have served a good number of customers. All which are very satisfied with our services. 
                    </p>
                </div>

                <div className="pt-14  px-7 border-t border-gray-300">
                    <h3 className='text-[#091542] text-[120px] font-[500] leading-[90px]' data-aos="fade-left" data-aos-duration="1000">2</h3>
                    <h4 className='text-[20px] font-[400] '>Branches</h4>
                    <p className='text-[12px]'>
                        We have branches across the globe that is at a 24Hr services call and support to our clients and customers. 
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About