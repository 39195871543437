import React, { useState } from 'react'
import Logo from "../assets/logo.png"
import { IoMenu } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

const Navbar = () => {
    const [openSidebar, setOpenSidebar] = useState(false)
  return (
    <div className='z-20 border-b-2 border-[#9747FF] fixed top-0 left-0 w-full bg-[#FEF2ED] flex items-center'>
        <IoMenu className='text-[30px] ml-5 text-[#9747ff] lg:hidden' onClick={()=>setOpenSidebar(true)} />
        <div className="container flex lg:justify-between justify-center items-center py-5 ">
            <div>
                <img src={Logo} alt="Epahubb consult" />
            </div>
            <nav>
                <ul className={`flex lg:flex-row flex-col lg:items-center lg:gap-10 gap-4 fixed lg:static top-0 ${openSidebar ? "left-0" : "-left-[300px] lg:left-0"} w-[250px] lg:w-full h-screen lg:h-auto lg:bg-transparent bg-white pt-[40px] lg:pt-0 px-[20px] lg:px-0`}>
                    <div className="flex justify-end mr-5 lg:hidden">
                        <IoMdClose className='text-black' onClick={()=>setOpenSidebar(false)} />
                    </div>
                    <div className='lg:hidden mb-[30px]'>
                        <img src={Logo} alt="" />
                    </div>
                    <li>
                        <a href="#home">
                            Home
                        </a>
                    </li>
                    <li>
                        <a href="#services">
                            Services
                        </a>
                    </li>
                    <li>
                        <a href="#work">
                            Our Work
                        </a>
                    </li>
                    <li>
                        <a href="#contact">
                            Contact Us
                        </a>
                    </li>
                    <a href='https://app.epahubb.com/sign-in/' className='py-2 px-7 rounded bg-[#018FAE] text-white lg:hidden'>
                        Login
                    </a>
                </ul>
            </nav>
            <a href='https://app.epahubb.com/sign-in/' className='py-2 px-7 rounded bg-[#018FAE] text-white lg:flex hidden'>
                Login
            </a>
        </div>
    </div>
  )
}

export default Navbar