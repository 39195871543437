import React from 'react'

const SkillItem = ({skill}) => {
  return (
    <div className='flex flex-col items-center justify-center gap-3 p-6 lg:p-2' data-aos="fade-up" data-aos-duration="300">
        <img src={skill?.icon} alt={skill?.title} />
        <h4 className='text-[20px] lg:text-[16px] text-center font-[600]'>
            {skill?.title}
        </h4>
        <p className='text-center text-[12px]'>
            {skill?.text}
        </p>
    </div>
  )
}

export default SkillItem