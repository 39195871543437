import React from 'react'

const FeatureItem = ({featureItem}) => {
  return (
    <div className='flex flex-col items-center justify-center gap-3'>
        <img src={featureItem?.icon} alt={featureItem?.title} data-aos="fade-up" data-aos-duration="700" />
        <h4 className='text-[24px] 8xl:text-[24px] lg:text-[20px] text-[#091542] font-[500]'>
            {featureItem?.title}
        </h4>
        <p className='text-center text-[14px] lg:text-[12px] 8xl:text-[14px]'>
            {featureItem?.text}
        </p>
    </div>
  )
}

export default FeatureItem