import React from 'react'

const Services = () => {
    const services = [
        {
            title:"Mobile Application Development",
            bgImage:"mobile-bg"
        },
        {
            title:"IT Consultancy",
            bgImage:"it-bg"
        },
        {
            title:"Software Engineering",
            bgImage:"software-bg"
        },
        {
            title:"Web Development",
            bgImage:"web-bg"
        },
        {
            title:"UI/UX Design",
            bgImage:"ui-bg"
        },
        {
            title:"Graphic Design",
            bgImage:"graphic-bg"
        },
    ]
  return (
    <div id='services' className='py-20'>
        <div className="container overflow-hidden">
            <h2 className='text-center text-[#091542] font-[700] md:text-[60px] text-[30px] mb-14' data-aos="fade-up" data-aos-duration="1000">EPAHUBB CONSULT</h2>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-9">
                
                <div  className={`bg-mobile-bg h-[450px] rounded flex items-center justify-center text-white md:p-10 text-center leading-[66px] font-[800] text-[30px] 8xl:leading-[50px] md:leading-[30px] 8xl:text-[46px] lg:text-[30px]`} data-aos="fade-up" data-aos-duration="1300">
                    <p>
                    Mobile Application Development
                    </p>
                </div>

                <div  className={`bg-it-bg h-[450px] rounded flex items-center justify-center text-white md:p-10 text-center leading-[66px] font-[800] text-[30px] 8xl:leading-[50px] md:leading-[30px]  8xl:text-[46px] lg:text-[30px]`} data-aos="fade-up" data-aos-duration="1300">
                    <p>
                    IT CONSULTANCY
                    </p>
                </div>

                <div  className={`bg-software-bg h-[450px] px-3 rounded flex items-center justify-center text-white md:p-10 text-center leading-[66px] font-[800] text-[30px] 8xl:leading-[50px] md:leading-[30px]  8xl:text-[46px] lg:text-[30px]`} data-aos="fade-up" data-aos-duration="1300">
                    <p>
                    SOFTWARE ENGINEERING
                    </p>
                </div>

                <div  className={`bg-web-bg h-[450px] rounded flex items-center justify-center text-white md:p-10 text-center leading-[66px] font-[800] text-[30px] 8xl:leading-[50px] md:leading-[30px]  8xl:text-[46px] lg:text-[30px]`} data-aos="fade-up" data-aos-duration="1300">
                    <p>
                        WEB DEVELOPMENT
                    </p>
                </div>

                <div  className={`bg-ui-bg h-[450px] rounded flex items-center justify-center text-white md:p-10 text-center leading-[66px] font-[800] text-[30px] 8xl:leading-[50px] md:leading-[30px]  8xl:text-[46px] lg:text-[30px]`} data-aos="fade-up" data-aos-duration="1300">
                    <p>
                    UI/UX DESIGN
                    </p>
                </div>

                <div  className={`bg-graphic-bg h-[450px] rounded flex items-center justify-center text-white md:p-10 text-center leading-[66px] font-[800] text-[30px] 8xl:leading-[50px] md:leading-[30px]  8xl:text-[46px] lg:text-[30px]`} data-aos="fade-up" data-aos-duration="1300">
                    <p>
                    GRAPHIC DESIGN
                    </p>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Services