import React from 'react'
import cartoon from "../assets/cartoon.png"
import chat from "../assets/chat.png"

const Customers = () => {
  return (
    <div className='min-h-screen md:py-40 py-14 pb-40'>
        <div className="container">
            <h2 className='md:text-[60px] text-[30px] font-[200] md:w-3/5 mx-auto text-center text-[#091542]'>
                What Our <span className='font-[700]'>Customers say </span> about us
            </h2>

            {/* CARDS */}
            <div className="grid md:grid-cols-2 grid-cols-1 gap-20 md:gap-5 mt-[25px]">
                <div className="py-8 px-8 box-shadow rounded-lg bg-[#00000004] relative">
                    <p>
                        I had my first ever Prop firm web application developed by Epahubb Consult. Guess what? its worth a while.
                    </p>
                    <div className='mt-10'>
                        <p>Elvis Justice</p>
                        <p>CEO. Trufunder Prop Firm LTD</p>
                    </div>
                    <img src={cartoon} className='absolute bottom-0 right-3' alt="" />
                </div>
                <div className="py-8 px-8 box-shadow rounded-lg bg-[#00000004] relative">
                    <p>
                        The school management system has really defined my way of understanding school administration. 
                    </p>
                    <div className='mt-10'>
                        <p>Vida Boateng</p>
                        <p>Ebenezer Mother Care Academy</p>
                    </div>
                    <img src={cartoon} className='absolute bottom-0 right-3' alt="" />
                </div>
            </div>

            <div className="flex items-center justify-center ">
                <img src={chat} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Customers