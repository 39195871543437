import React from 'react'

const Plan = () => {
  return (
    <div className='bg-plan-bg bg-cover bg-center h-[650px] flex items-center justify-center'>
        <div className="container">
            <h3 className='text-white md:text-[60px] text-[30px] font-[700] text-center md:w-4/5 mx-auto leading-tight'>
                Plan for the next level <span className='font-[200]'>of Technological Ingenuity with</span> Epahubb Consult
            </h3>
            <a href='#contact' className="flex items-center justify-center mt-20">
                <button className='bg-[#1C274E] px-6 py-3 text-white rounded'>
                    Contact Now
                </button>
            </a>
        </div>
    </div>
  )
}

export default Plan